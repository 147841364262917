<template>
  <div>
      <div class="vx-card p-6">
      <!-- Header -->
      <div style="display: grid; grid-template-columns: 200px 400px; justify-content: space-between">

        <!-- Search Form -->
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />

        <div style="display: grid; grid-template-columns: repeat(2, 1fr); grid-gap: 16px; align-items: center;">
          <v-select v-model="selectMode" :clearable="false" :options="modeOption" :change="getSpareparts"></v-select>
         <!-- <vs-button color="primary" type="border" icon-pack="feather" icon="icon-file">Export Data</vs-button> -->
          <vs-button color="primary" icon-pack="feather" icon="icon-plus" @click="addItem">Add Item</vs-button>
        </div>

      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="computedData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
      >
      </ag-grid-vue>

      <div id="footer" class="grid grid-cols-2">
        <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{
                  currentPage * paginationPageSize - (paginationPageSize - 1)
                }}
                -
                {{
                  spareparts.length - currentPage * paginationPageSize > 0
                    ? currentPage * paginationPageSize
                    : spareparts.length
                }}
                of {{ spareparts.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <vs-pagination :total="getTotalPage" :max="7" v-model="currentPage" />
      </div>
    </div>
      <vs-popup classContent="popup-example" :title="`${mode} sparepart`" :active.sync="popup">
        <div v-if="mode == 'add'">
        <div class="justify-center" >
          <div class="vx-row mt-2">
            <div class="vx-col md:w-1/1 w-full flex items-center gap-4">
              <label for="">Date: {{ !switchDate ? form.date : '' }} </label>
              <vs-input type="datetime-local" @change="formatDate" v-model="form.date" class="my-2" v-if="switchDate == true" />
            </div>  
          </div>
          <div class="flex gap-3">
            <label for="">Want to custom date?</label>
            <vs-switch color="dark" v-model="switchDate" />
          </div>
          
          <div class="vx-row my-2">
            <div class="vx-col md:w-1/1 w-1/2">
              <label>Picture</label>
              <vs-upload v-on:change="handleFileUpload" :multiple="false" limit="1" accept="image/jpeg, image/png, image/jpg" automatic />
            </div>
            <div class="vx-col md:w-1/1 w-1/2">
              <div class="vx-row my-2 w-full">
                <label for="">Title</label>
                <vs-input class="w-full" v-model="form.title" placeholder="Please write title" />
              </div>
              <div class="vx-row my-2 w-full">
                <label for="">Serial Number <small>(optional)</small></label>
                <vs-input class="w-full" v-model="form.serial_number" placeholder="Filled this or empty only" />  
              </div>
              <div class="vx-row my-2 w-full">
                <label class="mb-1">{{ $i18n.t('Category') }}</label>
                 <v-select class="w-full" v-model="form.category" :clearable="true" :options="categories" placeholder="Choose one category"></v-select>
              </div>
              <div class="vx-row my-2 w-full">
                <label class="mb-1">{{ $i18n.t('Brand') }}</label>
                <div v-if="form.category != null" class="w-full">
                  <v-select v-model="form.brand"  :clearable="true" :options="form.category.brands" placeholder="Choose one brand"></v-select>
                </div>
                <div class="w-full" v-else>
                  <v-select placeholder="Choose category first" disabled></v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-row my-2 px-4">
            <small class="mb-1">Note</small>            
            <vs-textarea class="w-full" v-model="form.note" />
          </div>
        </div>
        <div class="vx-row my-2 px-4 flex gap-3">
          <label for="">Attribute?</label>
          <vs-switch color="dark" v-model="switchAttr" />
          <small>Optional</small>    
        </div>
        <div v-if="switchAttr == true">
          <div class="vx-row my-2 flex justify-evenly items-end" v-for="(attr, index) in form.attribute" :key="index">
            <div class="vx-col md:w-1/1 w-1/4">
              <small class="mb-1">{{ $i18n.t('Key') }}</small>
              <vs-input class="w-full" v-model="attr.key" />
            </div>
            <div class="vx-col md:w-1/1 w-3/5">
              <small class="mb-1">{{ $i18n.t('Value') }}</small>
              <vs-input class="w-full" v-model="attr.value" />
            </div>
            <div class="vx-col md:w-1/1 w-auto">
              <feather-icon icon="Trash2Icon" class="text-danger cursor-pointer h-5 w-5" @click="deleteAttr(index)"></feather-icon>
            </div>
          </div>
          <vs-button color="primary" icon="add" size="small" class="w-full my-4" @click="addAttr">Add Attribute</vs-button>
        </div>
        <div class="justify-start my-4">
          <vs-button icon="save" @click="storeItem">Save</vs-button>
        </div>
        </div>
        <div v-if="mode == 'out'">
          <div class="justify-center" >
          <div class="vx-row mt-2">
            <div class="vx-col md:w-1/1 w-full flex items-center gap-4">
              <label for="">Date: {{ !switchDate ? form.date : '' }} </label>
              <vs-input type="datetime-local" @change="formatDate" v-model="form.date" class="my-2" v-if="switchDate == true" />
            </div>  
          </div>
          <div class="flex gap-3">
            <label for="">Want to custom date?</label>
            <vs-switch color="dark" v-model="switchDate" />
          </div>
          
          <div class="vx-row my-2">
            <div class="vx-col md:w-1/1 w-1/2">
              <label>Picture</label>
              <vs-upload v-on:change="handleFileUpload" :multiple="false" limit="1" accept="image/jpeg, image/png, image/jpg" automatic />
            </div>
            <div class="vx-col md:w-1/1 w-1/2">
              <div class="vx-row my-2 w-full">
                <label for="">Serial Number <small>(optional)</small></label>
                <vs-input class="w-full" v-model="form.serial_number" placeholder="Filled this or empty only" />  
              </div>
              <div class="vx-row my-2 w-full">
                <small class="mb-1">Note</small>            
                <vs-textarea class="w-full" rows="6" v-model="form.note" />
              </div>
            </div>
          </div>
          
        </div>
        <div class="justify-start my-4">
          <vs-button icon="save" @click="storeItem">Save</vs-button>
        </div>
        </div>
      </vs-popup>
  </div>
</template>
  
  <script>
  import vSelect from 'vue-select'
  import moment from 'moment';

  import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'
import CellRendererFile from './cell-renderer/CellRendererFile.vue'


  export default {
    components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererFile
  },
    data () {
      return {
        popup: false,
        mode: 'add',
        searchQuery: '',
        switchAttr: false,
        switchDate: false,
        selectMode: {
          label: "all of sparepart",
          value: 'all'
        },
        sparepart: null,
        modeOption: [
          {
            label: "all of sparepart",
            value: "all"
          },
          {
            label: "sparepart in",
            value: "in"
          },
          {
            label: "sparepart out",
            value: "out"
          }
        ],
        form: {
          attribute: [],
          date: "",
          picture: null,
          serial_number: null,
          note: null
        },
        formSparepartOut: {
          date: "",
          picture: null,
          serial_number: null,
          note: null
        },
        categories: [],
        spareparts: [],
        gridApi: null,
      getTotalPage: 0,
      gridOptions: {
        pagination: true,
        onPaginationChanged: this.onPaginationChanged,
      },
      defaultColDef: {
        sortable: true,
        resizable: true,
        wrapText: true,
        autoHeight: true,
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('Title'),
          field: 'title',
          filter: true,
          width: 255
        },
        {
          headerName: this.$i18n.t('Serial number'),
          field: 'sparepart_in.serial_number',
          filter: true,
          width: 240,
          // cellRendererFramework: 'CellRendererLink',
        },
        {
          headerName: this.$i18n.t('PIC In'),
          field: 'sparepart_in.pic',
          filter: true,
          width: 200, 
          cellStyle (params) {
            return {'line-height': 1.5}
          }
        },
        {
          headerName: this.$i18n.t('Date In'),
          field: 'sparepart_in.date', 
          filter: true,
          width: 235,
          cellStyle (params) {
            return {'line-height': 1.5}
          }
        },
        {
          headerName: this.$i18n.t('Status'),
          cellRendererFramework: 'CellRendererStatus',
          filter: true,
          width: 135,
          cellStyle (params) {
            return {'line-height': 1.5}
          }
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActions',
          headerClass: 'text-center',
          cellClass: 'text-center',
          cellRendererParams: {
            getSpareparts: this.getSpareparts.bind(this),
            deleteItem: this.deleteItem.bind(this),
            sparepartOut: this.sparepartOut.bind(this)
          },
          width: 210
        }
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions
      }
  
      }
    },
    computed: {
      paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    },
    computedData() {
      return this.spareparts 
    }
    },
    methods: {
      onPaginationChanged(){
      return this.getTotalPage = this.gridOptions.api.paginationGetTotalPages()
    },
      addItem(){
        this.popup = true
        this.mode = 'add'
        this.form = {
          date: "",
          picture: null,
          attribute: [],
          serial_number: null,
          note: null
        }
      },
      addAttr(){
        return this.form.attribute.push({
          key: "",
          value: ""
        })
      },
      deleteAttr(index){
        return this.form.attribute.splice(index, 1)
      },
      formatDate(){
        return moment(this.form.date).format('YYYY-MM-DD HH:mm');
      },
      handleFileUpload(name, files) {
        // Assuming only one file is uploaded
        if (files.length > 0) {
          this.form.picture = files[0];
        }
      },
      updateTime() {
        // Update the currentTime data property with the current time using Moment.js
        if (this.switchDate != true) {
          this.form.date = moment().format('YYYY-MM-DD HH:mm');
        }
      },
      sparepartOut(sparepart){
          this.mode = 'out'
          this.popup = true
          this.sparepart = sparepart
      },
      storeItem(){
        if (this.mode == 'add') {
          const formData = new FormData()
          formData.set("title", this.form.title)
          formData.set("attribute", JSON.stringify(this.form.attribute))
          formData.set("sparepart_category_id", this.form.category.code)
          formData.set("brand_id", this.form.brand.code)
          formData.set("date", this.form.date)
          formData.set("pic", this.$store.state.AppActiveUser.employee.name)
          formData.set("note", this.form.note)
          formData.set("picture", this.form.picture)
          formData.set("serial_number", this.form.serial_number)

          const payload = {
            status: 'in',
            body: formData
          }

          this.$store.dispatch("sparepart/storeSparepart", payload).then(
            (resp) => {
              this.popup = false
              this.$vs.notify({
                      title: "Successful",
                      text: resp.data.message,
                      iconPack: "feather",
                      icon: "icon-check-circle",
                      color: "success"
                    });
              this.getSpareparts()
              this.form = {
                attribute: [],
                date: "",
                picture: null,
                serial_number: null,
                note: null
              }
            }
          ).catch(
            err => {
              console.log(err)
              this.$vs.notify({
                  title: "Fail",
                  text: err.data.message,
                  iconPack: "feather",
                  icon: "icon-check-circle",
                  color: "danger"
                });
            }
          )
        } else if (this.mode == 'out') {
          const formData = new FormData()
          formData.set("date", this.form.date)
          formData.set("pic", this.$store.state.AppActiveUser.employee.name)
          formData.set("note", this.form.note)
          formData.set("picture", this.form.picture)
          formData.set("serial_number", this.form.serial_number)
          formData.set('sparepart_id', this.sparepart.id)

          const payload = {
            status: 'out',
            body: formData
          }

          this.$store.dispatch("sparepart/storeSparepart", payload).then(
            (resp) => {
              this.popup = false
              this.$vs.notify({
                      title: "Successful",
                      text: resp.data.message,
                      iconPack: "feather",
                      icon: "icon-check-circle",
                      color: "success"
                    });
              this.getSpareparts()
              this.form = {
                attribute: [],
                date: "",
                picture: null,
                serial_number: null,
                note: null
              }
            }
          ).catch(
            err => {
              console.log(err)
              this.$vs.notify({
                  title: "Fail",
                  text: err.data.message,
                  iconPack: "feather",
                  icon: "icon-check-circle",
                  color: "danger"
                });
            }
          )
        }
        
      },
      deleteItem(itemId){

      },
      getCategories(){
        this.$store.dispatch("sparepartCategory/getCategories").then(
          resp => {
            this.categories  = resp.data.values.map((category) => {
              return {
                label: category.category_name,
                code: category.id,
                brands: category.brands.map(brand => {
                  return {
                    label: brand.brand_name,
                    code: brand.id
                  }
                })
              }
            });
          }
        ).catch(
          err => {
            this.$vs.notify({
              title:'Opps something error',
              text: err.data.message,
              color:'danger'
            })
          }
        )
      },
      getSpareparts(){
        this.$store.dispatch("sparepart/getSpareparts", this.selectMode.value)
        .then(resp => {
          this.spareparts = resp.data.values.map(sparepart => {
            sparepart.sparepart_in.date = moment(sparepart.sparepart_in.date).format('YYYY-MM-DD HH:mm')
            return sparepart
          })
        }).catch(
          err => {
            this.$vs.notify({
              title:'Opps something error',
              text: err.data.message,
              color:'danger'
            })
          }
        )
      },
      updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
      },
    },
    mounted () {
      this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
    this.gridApi.sizeColumnsToFit()

    this.updateTime();
      // Update time every second
      setInterval(this.updateTime, 1000);

      
    },
    watch: {
      selectMode(){
        this.getSpareparts()
      }
    },
    created () {
      this.getSpareparts()
      this.getCategories()
    }
  }
  
  </script>
  
  <style lang="scss">
  #page-user-list {
    .user-list-filters {
      .vs__actions {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-58%);
      }
    }
  }
  // .vs-popup--content {
  //   overflow: visible !important;
  
  // }
  .vs-popup--content {
    // overflow: visible;
    max-height: 600px; /* Adjust the max-height according to your needs */
    overflow-y: auto;
  }


  </style>
  