<template>
    <div class="vx-row flex gap-2 justify-center">
      <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer" @click="showData" />
      <feather-icon icon="EditIcon" svgClasses="h-5 w-5  hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
      <feather-icon icon="LogOutIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" v-if="params.data.sparepart_out === null" @click="sparepartOut" />
    </div>
</template>

<script>
import recipe from "@/recipe";

export default {
  name: 'CellRendererActions',
  computed: {
    encrypt () {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);
    },

    isDetail(){
      if(this.$route.params.id){
        return true;
      }
      return false;
    }
  },
  methods: {
    showData () {
      this.$router.push({name: 'sparepart-show', params: { id: this.encrypt }});
    },
    editRecord () {
      this.$router.push({name: 'sparepart-edit', params: { id: this.encrypt }});
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Do you want to delete it ?`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$store.dispatch("sparepart/deleteSparepart", {
        id: this.params.data.id,
        status: 'in'  
      })
          .then((resp)   => {
            this.params.getSpareparts();
            this.showDeleteSuccess(resp)
          })
          .catch(err => {
            this.showDeleteError(err)
          })
    },
    showDeleteSuccess (resp) {
      this.$vs.notify({
        color: 'success',
        title: 'Delete success',
        text: resp.data.message
      })
    },
    showDeleteError (err) {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    },
    sparepartOut(){
      this.params.sparepartOut(this.params.data)
    }

  }
}
</script>
