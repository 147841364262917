<template>
  <div class="vx-row flex gap-2 mt-5">
    <vs-chip class="ag-grid-cell-chip" color="success">
      <span>in</span>
    </vs-chip>
    <vs-chip class="ag-grid-cell-chip" color="danger" v-if="params.data.sparepart_out != null">
      <span>out</span>
    </vs-chip>
  </div>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  data() {
    return {
     
    }
  },
  mounted() {

  },
  computed: {
    chipColor () {
      return (value) => {
        if (value === 'active') return 'success'
        else if (value === 'blocked') return 'danger'
        else if (value === 'deactivated') return 'warning'
        else return 'primary'
      }
    }
  }
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success),.15);
    color: rgba(var(--vs-success),1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning),.15);
    color: rgba(var(--vs-warning),1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger),.15);
    color: rgba(var(--vs-danger),1) !important;
    font-weight: 500;
  }
}
</style>
